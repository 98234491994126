import React, {useState, useEffect} from 'react';
import axios from 'axios';
import QRCode from "react-qr-code";
import request from 'request';

import {BsFillLightningChargeFill} from "react-icons/bs";

import { Modal, Spinner } from './Base';


// const Modal = ({showModal, onHide, children}) => {

//   return (
//     <>
//     {<div className='text-red-400'>Foo {showModal}</div>}
//     {showModal === 'true' ? <div className='text-green-400'>SHOW {showModal}</div> : <div className='text-green-400'>HIDE {showModal}</div> }
//     {showModal === 'true' &&
//       <div className="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
//         <div className="relative w-full max-w-md max-h-full">
//             <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
//               YAGA
//             </div>
//         </div>    
//       </div>}
//     </>
//   )
// };

let Button = ({variant, onClick, children}) => {
  return (
    <>
    <button className="button-coinbase" onClick={onClick}>{children}</button>
    </>
  )
};

let Alert = ({variant, children}) => {
  return (
    <>
    <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
      <p className="font-bold">Heads up!</p>
      <p className="text-sm">{children}</p>
    </div>
    </>
  )
};

let Form = ({children}) => {
  return (
    <>
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
      {children}
    </div>
    </>
  )
};




let InvoiceDetails = ({apiEndpoint, token, paymentHash, handleClose}) => {

  let [count, setCount] = useState(180000); // 3 minutes
  let [invoice, setInvoice] = useState(null);
  let [error, setError] = useState(null);
 

  useEffect(() => {
    if(paymentHash){
      pollInvoice(paymentHash);
    }
  }, [paymentHash]);

  let pollInvoice = (payment_hash) => {  
    
    let url = `${apiEndpoint}/invoice_payment/by_payment_hash/${payment_hash}`;
    console.log("url", url);
    let headers = {
      "Authorization": `Bearer ${token}`
    };
    axios.get(url, { headers: headers })
    .then((response) => {
      console.log("response", response);
      setInvoice(response.data);
      if(response.data.status === "COMPLETED"){
        console.log("completed");
        setTimeout(() => {
          handleClose();
        }, 3000);      
      }else{
        console.log("pending");
        if(count>0){
          setTimeout(() => {
            setCount(count-=3000);
            pollInvoice(payment_hash);
          }, 3000);
        } else {
          console.log("timeout");
          setInvoice(null);
          handleClose();
        }

      }    
    })
    .catch((error) => {
      console.log("error", error);
      setError(error.message);
    });

  };

  return (
    <>
    
    {invoice ?
      <div className='flex flex-col justify-start'>
        <div className="flex flex-row justify-center">
            {invoice.status === "CREATED" && <div className="font-bold p-1 m-1 text-white bg-slate-800 rounded-lg">{invoice.status}</div>}
            {invoice.status === "PENDING" && <div className="font-bold  p-1 m-1 text-white bg-slate-800 rounded-lg">{invoice.status}</div>}
            {invoice.status === "PAID" && <div className="font-bold p-1 m-1 text-white bg-slate-800 rounded-lg">{invoice.status}</div>}
            {invoice.status === "COMPLETED" && <div className="font-bold p-1 m-1 text-white bg-green-800 rounded-lg">{invoice.status}</div>}   
        </div>
        <div className="flex flex-row justify-center">
            <div className='bg-cyan-800 m-1 p-1 rounded-lg text-center text-yellow-400 font-mono text-2xl'>
              {invoice.amount} SATs
            </div>
        </div>        
        {invoice.status !== "COMPLETED" && 
        <div className='flex flex-col justify-center items-center'>    
            <div className='bg-white p-3 text-center w-[266] h-[266] flex flex-col items-center justify-center rounded-lg'>
              <QRCode size={256} value={invoice.payment_request} /></div>
            <div className='text-break mb-2 text-xs flex flex-col'>
              <div>Payment Request</div> 
              <div className='text-slate-500 text-mono break-all'>{invoice.payment_request}</div>
            </div>
            <div className='text-break mb-2 text-xs flex flex-col'>
              <div>Payment Hash</div> 
              <div className='text-slate-500 text-mono break-all'>{invoice.payment_hash}</div>
            </div>
            <div>Time Remaining: {parseInt(count/1000)} s</div>
        </div>}
      </div>:
      <div><Spinner/></div>}
    </>
  );
};

let PaymentForm = ({amountSats, memo, handleCreateInvoice}) => {

  const [formState, setFormState] = useState({
    "amount_sats": amountSats,
    "memo":memo
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target
    const value = target.value
    const name = target.name

    setFormState((formState) => ({
      ...formState,
      [name]: value
    }));  
  };  


  return (
    <>
    <div className="flex flex-col justify-end">
      <input className="input-common" name="amount_sats" type="text" placeholder="Enter amount in SATs" onChange={(e)=>handleInputChange(e)}/>
      <input className="input-common" name="memo" type="text" placeholder="Memo" onChange={(e)=>handleInputChange(e)}/>
      <button className="btn-common" onClick={()=>handleCreateInvoice(formState)}>Create Payment Invoice</button>
    </div>

    {/* <Form>
      <Form.Group className="mb-3" controlId="amount_sats">
        <Form.Label>Amount (SATs)</Form.Label>
        <Form.Control name="amount_sats" type="text" placeholder="Enter amount in SATs" onChange={(e)=>handleInputChange(e)}/>
        <Form.Text className="text-muted">
          Ony enter amounts in whole satoshis.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="memo">
        <Form.Label>Memo</Form.Label>
        <Form.Control name="memo" type="text" placeholder="Memo" onChange={(e)=>handleInputChange(e)}/>
        <Form.Text className="text-muted">
          If its important that I know why I have been paid and by who, put it here. Its totally secure and private.
        </Form.Text>
      </Form.Group>

      <Button variant="primary" onClick={()=>handleCreateInvoice(formState)}>
        Create Payment Invoice
      </Button>
    </Form> */}

    </>
  );
};

export const PayBitcoinRapaygo = ({}) => {


  const apiEndpoint = "https://api.rapaygo.com/v1";
  const apiKey = "aoaJRPBVQhF7ZiGJ";
  const apiSecret = "GeC3k6YA5ij4hNET";


  // const [amount, setAmount] = useState(1000);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setInvoice(null);
    setShowModal(false);
  }

  const handleInvoiceCompleted = () => {
    setInvoice(null);
    setShowModal(false);
  }

  const handleShow = () => {
    console.log("handleShow");
    setShowModal(true);
  };


  let handleCreateInvoice = (formState) => {

    var data = JSON.stringify({
      "key": apiKey,
      "secret": apiSecret
    });
    
    var config = {
      method: 'post',
      url: `${apiEndpoint}/auth/key`,
      withCredentials: false,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setToken(response.data.access_token);
      console.log("token", token);

      var data = JSON.stringify({
        "amount_sats": parseInt(formState.amount_sats),
        "memo": formState.memo
      });
      
      var config = {
        method: 'post',
        url: `${apiEndpoint}/invoice_payment/ln/invoice`,
        withCredentials: false,
        headers: { 
          'Authorization': response.data.access_token, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setInvoice(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
      
    })
    .catch(function (error) {
      console.log(error);
    });
     
    
  };


  return (
    <>
    <div onClick={()=>handleShow()} className='btn-common flex flex-row items-center'>
      <BsFillLightningChargeFill className='text-2xl'/>
      <span className='ml-2'>Pay With Lightning Sats</span>
    </div>
    <Modal showModal={showModal}>
      <div>
        <div className="flex flex-col justify-end">
            <div className='text-2xl'>Pay Clay in Satoshis</div>
            {error && <Alert className="payment" variant="danger">{error}</Alert>}
            {invoice ? 
            <InvoiceDetails 
              apiEndpoint={apiEndpoint} 
              token={token} 
              paymentHash={invoice.payment_hash}
              handleClose={handleInvoiceCompleted}/> :
            <PaymentForm 
              amountSats={1000} 
              memo={'payment for being awesome'} 
              handleCreateInvoice={handleCreateInvoice}/>}
            <div className="flex flex-row justify-end items-start">
              <button onClick={()=>handleClose()} className="btn-common w-full">Cancel</button>
            </div>
        </div>  
      </div>
    </Modal>
    
    </>
  )
};


