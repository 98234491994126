import React, { useEffect, useState } from "react";
import Axios from "axios";
import {FaLock, FaBitcoin, FaPaypal, FaLink} from 'react-icons/fa';

import "./BtcPay.css"

export const BtcPay = ({className}) => {

  const _className = `btn-common ${className ? className : 'ml-1 md:ml-4 btcpay-form btcpay-form--inline h-fit'}`

  const [formState, setFormState] = useState({
    storeId: "6hpN8Q3YQgWVHLrDf6fsU8FNmYJJTyZSkwTmwsHMK2SS",
    jsonResponse: true,
    checkoutDesc: "pay @claytantor",
    browserRedirect: "https://claytantor.com",
    notifyEmail: "claytantor@gmail.com",
    price: 1.0,
    currency: "USD",
    defaultPaymentMethod: "BTC",
  });

  useEffect(() => {
    // Recreate client
    // const pk = btcpay.crypto.generate_keypair().getPrivate('hex');
    // const keypair = btcpay.crypto.load_keypair(new Buffer.from(pk, 'hex'))
    // const client = new btcpay.BTCPayClient("https://btcpay0.voltageapp.io/", keypair, {merchant: "GExrWUPNDkVwWdVGxs8RzsWZUg8NjYPM7nLZgdVY6mpH"})
    // setClient(client);
  }, []);

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the form data to the server using myAxios
    Axios({
      method: "post",
      url: "https://btcpay0.voltageapp.io/api/v1/invoices",
      data: formState,
    }).then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <form
        action="https://btcpay0.voltageapp.io/api/v1/invoices" 
        method="POST" 
        className={_className}>
        <button type="submit" className="h-[70] md:h-8 w-full md:w-fit p-2 flex flex-row justify-center items-center cursor-pointer bg-slate-600 hover:bg-cyan-600 text-white font-bold rounded-l-lg">
            <><FaBitcoin className="h-fill text-2xl mr-1"/></>Pay USD Amount In Bitcoin
        </button>
        <input type="hidden" name="storeId" value="6hpN8Q3YQgWVHLrDf6fsU8FNmYJJTyZSkwTmwsHMK2SS" />
        <input type="hidden" name="currency" value="USD" />
        <input type="hidden" name="browserRedirect" value="https://claytantor.com" />
        <input onChange={handleInputChange}
              className="btcpay-input-price rounded-r-lg focus:outline-yellow-500 focus:outline-2 focus:outline-solid h-[70] md:h-8 text-black bg-white p-2 flex flex-row justify-center items-center cursor-pointer hover:cursor-pointer hover:text-white hover:bg-slate-500"
              type="number"
              name="price"
              min="1"
              max="2000"
              value={formState.price}
              data-price="1"/>
      
      </form>
    </>
  );
};
