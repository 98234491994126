import React from "react";

import { BsYoutube, BsWordpress } from "react-icons/bs";
import { FaPodcast, FaGithub } from "react-icons/fa";
import { FiCloudLightning } from "react-icons/fi";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { GiBrain } from "react-icons/gi";
import { FaLock, FaBitcoin, FaPaypal, FaLink } from "react-icons/fa";
import {
  SiBigcartel,
  SiInstagram,
  SiTwitter,
  SiTwitch,
  SiLinkedin,
} from "react-icons/si";
import {CgSpinnerTwo} from "react-icons/cg";

export const Alert = ({ background, text, children }) => {
  return (
    <div className={`p-2 flex flex-row rounded ${background} ${text} w-full`} role="alert">
      {children}
    </div>
  );
};

export const HelpAlert = ({ children, helpLink }) => {
  return (
    <Alert background="bg-pink-100" text="text-slate-800">
        <div className="flex flex-row justify-end">
            <div>{children}</div>
        </div>      
    </Alert>
  );
};

export const Modal = ({ showModal, children }) => {
  return (
    <>{showModal && 
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
              <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
                  <div className="flex flex-col w-full justify-end">
                      <div>
                          {children}
                      </div>
                  </div>
                  
              </div>
          </div>
      </div>
    }</>       
  );
};

export const Badge = ({ variant, children }) => {
  return <span className={`badge badge-${variant}`}>{children}</span>;
};

export const Spinner = ({textColor="yellow-200"}) => {
  return (
    <>
    <div className="inline-block w-9 h-9 border-2 rounded-full" role="status">
        <CgSpinnerTwo className={`bg-slate-700 w-8 h-8 animate-spin text-${textColor} rounded-full`}/>
    </div>
    </>
  )
};

export const QuickLink = ({ href, alt, children, src, icon, className }) => {
  const localClass = `underline flex flex-row justify-center items-center rounded-lg hover:cursor-pointer hover:text-white m-2 p-2 ${
    className ? className : "bg-cyan-900 text-cyan-200 hover:bg-cyan-700"
  }`;

  return (
    <>
      <div onClick={() => window.open(href)} className={localClass}>
        {src && (
          <img
            className="mr-2 rounded w-12 h-12 md:w-12 md:h-12 sm:h-12 sm:w-12"
            src={src}
            alt={alt}
          />
        )}
        {icon && <span className="mr-2 text-2xl">{icon}</span>}
        <span>{children}</span>
      </div>
    </>
  );
};

export const AppLink = ({ href, alt, children, src, icon, className }) => {
  const localClass = `text-white underline flex flex-col justify-center items-center rounded-lg hover:cursor-pointer hover:text-white m-2 p-2 ${
    className ? className : ""
  }`;

  return (
    <>
      <div onClick={() => window.open(href)} className={localClass}>
        {src && (
          <img
            className="mr-2 rounded w-12 h-12 md:w-12 md:h-12 sm:h-12 sm:w-12"
            src={src}
            alt={alt}
          />
        )}
        {icon && <span className="mr-2 text-6xl">{icon}</span>}
        <span>{children}</span>
      </div>
    </>
  );
};

export const ImageBox = ({ href, title, children, imageSrc }) => {
  const iconClass = `hover:cursor-pointer rounded-lg w-[300] h-[300] relative bg-transparent border-none p-2 text-white text-center hover:before:bg-cyan-400 hover:before:opacity-20 before:opacity-0 before:absolute before:inset-0 before:bg-black before:content-[''] overflow-hidden m-2`;

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div
          onClick={() => window.open(href)}
          className={iconClass}
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: "cover",
          }}
        >
          <div className="z-10 relative top-10">
            <div className="mt-8 flex flex-col items-center justify-center">
              {children && <span>{children}</span>}
            </div>
          </div>
        </div>
        {title && (
          <span className="text-white text-shadow-lg text-2xl text-center">
            {title}
          </span>
        )}
      </div>
    </>
  );
};

export const IconBox = ({
  href,
  title,
  children,
  description,
  icon,
  className,
}) => {
  const iconClass = `flex flex-col justify-center items-center rounded-lg hover:cursor-pointer hover:text-white w-[256] h-[256] m-2 p-2 ${
    className ? className : "bg-cyan-900 text-cyan-200 hover:bg-cyan-700"
  }`;

  return (
    <>
      <div onClick={() => window.open(href)} className={iconClass}>
        {/* {src && <img className="mr-2 rounded w-10 h-10 md:w-10 md:h-10 sm:h-10 sm:w-10" src={src} alt={alt} />}  */}
        {icon && <span className="text-8xl">{icon}</span>}
        {title && (
          <span className="text-2xl font-bold text-center">{title}</span>
        )}
        {description && <span className="text-center">{description}</span>}
        {children && <span>{children}</span>}
      </div>
    </>
  );
};
