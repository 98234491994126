import React from "react";

import {BsYoutube, BsWordpress} from 'react-icons/bs';
import {FaPodcast, FaGithub} from 'react-icons/fa';
import {FiCloudLightning} from 'react-icons/fi';
import {AiOutlineCloudDownload} from 'react-icons/ai';
import {GiBrain} from 'react-icons/gi';
import {FaLock, FaBitcoin, FaPaypal, FaLink} from 'react-icons/fa';
import {SiBigcartel, SiInstagram, SiTwitter, SiTwitch, SiLinkedin} from 'react-icons/si';
import {SiCashapp} from 'react-icons/si';

import { QuickLink, AppLink, ImageBox, IconBox } from "./components/Base";
import {BtcPay} from "./components/BtcPay";
import {PayBitcoinRapaygo} from "./components/PayBitcoinRapaygo";

import imgSrcClayIcon from "./assets/img/AVATAR_2023.png";
import imgWearable from "./assets/img/wearable.png";
import imgVirarch from "./assets/img/virarch.png";
import img3dDesign from "./assets/img/3d_design.png";
import imgDigitalArt from "./assets/img/digital_art.png";
import imgHandwork from "./assets/img/handwork_art.png";

const info = {
  version: '3.0.1',
  copyright: '1995-2023'
}

const links = [
  {
    icon: <><BsYoutube/></>,
    alt: 'claytantor On YouTube',
    href: 'https://www.youtube.com/@claytantor',
    // src: imgSrcTRF,
    children: <div className="text-lg md:text-3xl uppercase">THE RICHEST FAMILY ON YOUTUBE</div>
  },
  {
    icon: <><FaPodcast/></>,
    alt: 'The Richest Family Podcast',
    href: 'https://audioboom.com/channels/5105130-the-richest-family',
    // src: imgSrcTRF,
    children: <div className="text-lg md:text-3xl uppercase">THE RICHEST FAMILY PODCAST</div>
  },
  {
    icon: <><SiBigcartel/></>,
    alt: 'claytantor big cartel store',
    href: 'https://claytantor.bigcartel.com/',
    // src: imageBalac,
    children: <div className="text-lg md:text-3xl uppercase">BUY STUFF I DESIGNED</div>
  },
  {
    icon: <><FaLock/></>,
    alt: 'claytantor public key',
    href: 'https://claytantor.wordpress.com/2021/05/14/my-public-key/',
    children: <div className="text-lg md:text-3xl uppercase">SEND SECURE MESSAGE VIA PGP</div>
  },
  {
    icon: <><FaLock/></>,
    alt: 'claytantor public key nostr',
    href: 'https://claytantor.wordpress.com/2023/01/07/my-nostr-101/',
    children: <div className="text-lg md:text-3xl uppercase">SEND SECURE MESSAGE VIA NOSTR</div>
  },
]



const techie = [
  {
    icon: <><AiOutlineCloudDownload/></>,
    title: 'Cloud Applications',
    description: 'Creating cloud native applications and APIs means getting the value out quickly.',
    href: 'https://claytantor.wordpress.com/category/cloud-applications/',
  },
  {
    icon: <><FiCloudLightning/></>,
    title: 'Cloud Devops',
    description: 'Empower the people who wrote the software to help it to have a healthy life.',
    href: 'https://claytantor.wordpress.com/category/cloud-devops/',
  },
  {
    icon: <><GiBrain/></>,
    title: 'Artificial Intelligence',
    description: 'Building highly curated stable baselines models for specialized use cases.',
    href: 'https://claytantor.wordpress.com/category/ai-and-big-data/',
  },
  {
    icon: <><FaLink/></>,
    title: 'Blockchain',
    description: 'Blockchain technology has the potential to revolutionize distributed truth .',
    href: 'https://claytantor.wordpress.com/category/blockchain/',
  },
]

const design = [
  {
    title: 'Wearable Sculpture',
    href: 'https://claytantor.wordpress.com/category/wearable-sculpture/',
    imageSrc: imgWearable
  },
  {
    title: 'Virtual Architecture',
    href: 'https://claytantor.wordpress.com/category/virtual-architecture/',
    imageSrc: imgVirarch
  },
  {
    title: 'Digital Art',
    href: 'https://claytantor.wordpress.com/category/digital-art/',
    imageSrc: imgDigitalArt
  },
  {
    title: '3D Design',
    href: 'https://claytantor.wordpress.com/category/3d-design/',
    imageSrc: img3dDesign
  },
  {
    title: 'Handwork',
    href: 'https://claytantor.wordpress.com/category/handwork/',
    imageSrc: imgHandwork
  } 
]

const appLinkText = "text-lg md:text-sm uppercase";

const apps = [
  {
    icon: <><SiInstagram/></>,
    alt: 'claytantor instagram',
    href: 'https://www.instagram.com/claytantor/',
    children: <div className={appLinkText}>instagram</div>
  },
  {
    icon: <><SiTwitter/></>,
    alt: 'claytantor twitter',
    href: 'https://twitter.com/claytantor',
    children: <div className={appLinkText}>twitter</div>
  },
  {
    icon: <><FaGithub/></>,
    alt: 'claytantor github',
    href: 'https://github.com/claytantor',
    children: <div className={appLinkText}>github</div>
  },
  {
    icon: <><BsYoutube/></>,
    alt: 'claytantor On YouTube',
    href: 'https://www.youtube.com/@claytantor',
    children: <div className={appLinkText}>YOUTUBE</div>
  },
  {
    icon: <><FaPodcast/></>,
    alt: 'claytantor on audioboom',
    href: 'https://audioboom.com/channels/5105130-the-richest-family',
    children: <div className={appLinkText}>audioboom</div>
  },
  {
    icon: <><SiBigcartel/></>,
    alt: 'claytantor big cartel store',
    href: 'https://claytantor.bigcartel.com/',
    children: <div className={appLinkText}>BIG CARTEL</div>
  },
  {
    icon: <><BsWordpress/></>,
    alt: 'claytantor wordpress blog',
    href: 'https://claytantor.wordpress.com',
    children: <div className={appLinkText}>wordpress</div>
  },
  {
    icon: <><SiTwitch/></>,
    alt: 'claytantor twitch',
    href: 'https://www.twitch.tv/claytantor',
    children: <div className={appLinkText}>twitch</div>
  },
  {
    icon: <><SiLinkedin/></>,
    alt: 'claygraham linkedin',
    href: 'https://www.linkedin.com/in/claygraham/',
    children: <div className={appLinkText}>linkedin</div>
  }
]

const DonatePaypal = ({className}) => {
  const _className = `btn-common ${className ? className : 'btn-large w-full p-2'}`
  return (<>
      <div onClick={()=>window.open('https://www.paypal.com/donate/?hosted_button_id=8WWZY5QFE2RAJ','_blank')}
      className={_className}>
        <FaPaypal className="text-2xl mr-1"/> Pay on Paypal
      </div>
    </>)
};

const DonateCoinbase = ({ icon, name }) => {
  return (<>
    <div>
      <div onClick={()=>window.open('https://commerce.coinbase.com/checkout/73e86dd3-611f-4dd6-b704-88efac994161','_blank')}  
      className="donate-with-crypto btn-common btn-large w-fit p-2">
        {icon} Pay with {name}
      </div>
      <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
      </script>
    </div>

  </>);
};


export const App = () => {
    return (
    <div className="root font-sans">
      {/* header */}
      <div className="header flex flex-col md:flex-row bg-slate-800">
        <div className="w-full">
          <div className="flex flex-col md:flex-row justify-center items-start p-4">
            <img
              className="m-4 rounded-full w-32 h-32 md:w-64 md:h-64 sm:h-32 sm:w-32"
              src={imgSrcClayIcon}
              alt="Clay Ride"
            />
            <div className="m-4 flex flex-col">
              <h1 className="text-4xl md:text-5xl text-white font-bold leading-tight mb-4">
                Hi, I'm <span className="text-slate-200">Clay</span>
              </h1>
              <h4 className="font-sans text-lg md:text-2xl text-white leading-tight mb-4">
                I'm a software architect, coder, designer of virtual and real
                  things, artist, and lifelong maker. On the internet I go by
                  the handle of <span className="font-mono">@claytantor</span>
              </h4>
              <div className="flex flex-wrap w-full">
                <DonatePaypal className={'btn-common w-full md:w-fit p-2'}/>
                <div className="btn-common" onClick={()=>window.open('https://cash.app/$claytantor',target='_new')}>
                  <SiCashapp className="text-2xl mr-2"/>
                  <span>Pay With Cashapp</span>
                </div>
                {/* bitcoin:bc1qnraqvlvut5fr5jgwxqdzxtlyx9093ug7qs33zx?amount=0.0001&time=1690354701&exp=86400 */}
                <PayBitcoinRapaygo/>
              </div>

            </div>
            
          </div>
        </div>
      </div>
      {/* section container */}
      <div className="flex flex-col md:flex-row bg-teal-900 p-4">
        <div className="w-full flex flex-col justify-center">
          <h1 className="mt-4 text-center text-4xl md:text-5xl text-white font-bold leading-tight">
            Links
          </h1>
          <div className="p-4 mb-3 flex flex-col justify-center items-center w-full">
            {
              links.map((link, index) => {
                  return (
                      <QuickLink key={index} {...link} className="bg-teal-900 text-teal-200 hover:bg-teal-700"/>
                  )
              })
            }
          </div>
        </div>
      </div>
      {/* section container */}
      <div className="flex flex-col md:flex-row bg-cyan-800 p-4">
        <div className="w-full flex flex-col justify-center">
          <h1 className="mt-4 text-center text-4xl md:text-5xl text-white font-bold leading-tight">
            Techie Stuff
          </h1>
          <div className="p-4 mb-3 flex flex-wrap justify-center items-center w-full">
            {
                  techie.map((link, index) => {
                      return (
                          <IconBox key={index} {...link} className="bg-cyan-900 text-cyan-200 hover:bg-cyan-700"/>
                      )
                  })
            }
          </div>          
        </div>
      </div>

      {/* section container */}
      <div className="flex flex-col md:flex-row bg-slate-800 p-4">
        <div className="w-full flex flex-col justify-center">
          <h1 className="mt-4 text-center text-4xl md:text-5xl text-white font-bold leading-tight">
            Design
          </h1>
          <div className="p-4 mb-3 flex flex-wrap justify-center items-center w-full">
            {
              design.map((link, index) => {
                  return (
                      <ImageBox key={index} {...link} />
                  )
              })
            }
          </div>          
        </div>
      </div>

      {/* section container */}
      <div className="flex flex-col md:flex-row bg-slate-900 p-4">
        <div className="w-full flex flex-col justify-center">
          <h1 className="mt-4 text-center text-4xl md:text-5xl text-white font-bold leading-tight">
            All The Apps
          </h1>
          <div className="p-4 mb-3 flex flex-wrap justify-center items-center w-full">
            {
              apps.map((link, index) => {
                  return (
                      <AppLink key={index} {...link} />
                  )
              })
            }
          </div>          
        </div>
      </div>

      {/* footer */}
      <footer className="h-fit min-h-[100] bg-black text-white flex 
            flex-row justify-between p-2">
            <div className="flex flex-col mb-8">
                <div className="text-lg font-semibold flex flex-row justify-start p-2 items-center">
                    <img src={imgSrcClayIcon} alt="claytantor" className="mr-2 inline-block h-12 w-12 rounded-full align-middle" />
                    @claytantor
                </div>
                <div>Clay is a software architect, coder, designer of virtual and real things, artist, and lifelong maker.</div>
                {info && <div>v{info.version} @copyright {info.copyright}</div>}
            </div>
            <div></div>

      </footer> 
    </div>

  );
};
